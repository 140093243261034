// @flow
import {
  ApolloClient,
  split,
  HttpLink,
  ApolloLink,
  defaultDataIdFromObject,
  InMemoryCache,
  ApolloCache,
} from '@apollo/client';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { onError } from '@apollo/client/link/error';
import { getMainDefinition } from '@apollo/client/utilities';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import { usePregeneratedHashes } from 'graphql-codegen-persisted-query-ids/lib/apollo';
import omitDeep from 'omit-deep-lodash';
import { persistCacheSync } from './cachePersistor';
import { GRAPHQL_URL, GRAPHQL_WS_URL } from 'config';
import { APP_VERSION } from 'config/constants';
import { isAuthlessPathname } from 'utils/router';
import { uniqBy, mergeWithKey } from 'ramda';
import type { FieldFunctionOptions, SafeReadonly } from '@apollo/client';
import { createClient } from 'graphql-ws';
import { Kind, OperationTypeNode } from 'graphql';
import { precacheLink } from 'modules/Apollo/precacheLink';
import analytics from 'modules/analytics';
// $FlowIgnore[untyped-import] generated by codegen so we know it's safe
import persistedQueriesData from 'generated/persisted-queries';
import { LocalForageWrapper } from 'apollo3-cache-persist';
import localforage from 'localforage';
import { isRefreshTokenProvided } from 'utils/token';
import { logger } from '../logger';
import type { Role } from 'generated/graphql';

const httpLink = new HttpLink({
  uri: GRAPHQL_URL,
  credentials: 'include',
  includeExtensions: true,
});

const AUTHLESS_OPERATIONS = [
  'authenticate',
  'requestPasswordReset',
  'resetPassword',
  'authenticateMFA',
  'confirmAccount',
];

export const requiresAuthMiddleware: ApolloLink = new ApolloLink((operation, forward) => {
  if (AUTHLESS_OPERATIONS.includes(operation.operationName) || isRefreshTokenProvided()) {
    return forward(operation);
  }

  logger.info(`[Apollo Client]: Skipping ${operation.operationName}`);
  return null;
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: GRAPHQL_WS_URL,
  })
);

const transportLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === Kind.OPERATION_DEFINITION &&
      definition.operation === OperationTypeNode.SUBSCRIPTION
    );
  },
  wsLink,
  httpLink
);

// This makes sure to add the read session related headers to all requests
// we will then read them on the graphql server and add them to the Datadog context
const readSessionLink = new ApolloLink((operation, forward) => {
  const readSession = analytics.readSession;

  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      'x-read-session': readSession != null ? JSON.stringify(readSession) : null,
    },
  }));

  return forward(operation);
});

const logoutLink = onError(({ graphQLErrors, networkError, forward }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path, extensions }) => {
      if (extensions?.code !== 'UNAUTHENTICATED') {
        console.error(
          `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
            locations
          )}, Path: ${path}`
        );
      }
    });

    if (networkError) {
      if (networkError.message !== 'Failed to fetch') {
        //These console logs will have a linked Error within analytics Tools (DataDog, etc).
        console.error(`[Network error]: ${networkError.message}`);
      } else {
        //These can be from CORS, internet instability
        console.error(`[Unknown Network error]: ${networkError.message}`);
      }
    }

    if (
      graphQLErrors.some(
        (error) =>
          // Only redirect to logout if the AUTHENTICATED error is originated
          // from a page that requires authentication
          error.extensions?.code === 'UNAUTHENTICATED' &&
          !isAuthlessPathname(window.location.pathname)
      )
    ) {
      window.location.href = '/logout';
    }
  }
});

const mergeById =
  (idKey: string) =>
  (
    existing: SafeReadonly<$FlowFixMe>,
    incoming: SafeReadonly<$FlowFixMe>,
    { readField, args }: $FlowFixMe
  ) => {
    // We only want to merge for pagination, using cursor as pagination flag.
    const items = args?.cursor ? { ...existing?.items } : {};
    incoming.items?.forEach((item) => {
      const key = readField<string>(idKey, item);
      if (key != null) {
        items[key] = item;
      }
    });
    return { ...incoming, items };
  };

const readFromMap = (existing: SafeReadonly<$FlowFixMe>) => {
  return existing != null
    ? {
        ...existing,
        items: Array.from(Object.values(existing.items)),
      }
    : existing;
};

const cache: ApolloCache<$FlowFixMe> = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        workListItems: {
          keyArgs: [
            'sortOrder',
            'sortColumn',
            'statsOnTop',
            'claimedBy',
            'site',
            'patientType',
            'patientSex',
            'patientDob',
            'modality',
            'priority',
            'status',
            'filter',
            'smids',
            'dateRange',
            'mrn',
            'accessionNumber',
          ],
          merge: mergeById('smid'),
          read: readFromMap,
        },
        hangingProtocols: {
          merge: false,
        },
        toolPreferences: {
          merge: false,
        },
        studyDescriptions: {
          keyArgs: ['search'],
        },
        studies: {
          keyArgs: [
            'smids',
            'filter',
            'modality',
            'mrn',
            'bodyPart',
            'dateRange',
            'sortColumn',
            'sortOrder',
            'searchSmid',
          ],
          merge: mergeById('smid'),
          read: readFromMap,
        },
        report: {
          keyArgs: ['smid'],
          merge: true,
        },
        templates: {
          keyArgs: ['owners', 'procedures', 'search'],
          merge: (existing, incoming, { readField }) => {
            const uniqueItems = (k: string, l: $FlowFixMe[], r: $FlowFixMe[]) => {
              return k === 'items'
                ? uniqBy((item) => readField<string>('id', item), [...l, ...r])
                : r;
            };

            // $FlowFixMe[incompatible-use]
            return mergeWithKey(uniqueItems, existing, incoming);
          },
        },
        macros: {
          keyArgs: ['owners', 'procedures', 'search'],
          merge: (existing = { items: [] }, incoming, { readField }) => {
            return {
              ...incoming,
              items: uniqBy(
                (item) => readField('smid', item),
                [...existing.items, ...incoming.items]
              ),
            };
          },
        },
        addendums: {
          merge: (existing = [], incoming, args) => {
            return uniqBy((item) => item.__ref, [...existing, ...incoming]);
          },
        },
      },
    },
    User: {
      fields: {
        roles: {
          merge(existing: Role[] = [], incoming: Role[], args: FieldFunctionOptions<>) {
            // Roles are set only at login so there is not need to replace them.
            return incoming.length > 0 ? incoming : existing;
          },
        },
      },
    },
    ProcedureReference: {
      // Include 'smid' and 'autoload' as keyFields to uniquely identify ProcedureReferences.
      // This accounts for cases where the same 'smid' has different 'autoload' values in
      // different queries (GET_REPORT_TEMPLATE vs. GET_CURRENT_WORKLIST_REPORT), ensuring
      // accurate cache representation for each context.
      keyFields: ['smid', 'autoload'],
    },
    StudyDescriptions: {
      fields: {
        items: {
          merge(existing = [], incoming, args: FieldFunctionOptions<>) {
            return uniqBy((item) => item.__ref, [...existing, ...incoming]);
          },
        },
      },
    },
    Macros: {
      fields: {
        items: {
          merge(existing = [], incoming, args: FieldFunctionOptions<>) {
            return uniqBy((item) => item.__ref, [...existing, ...incoming]);
          },
        },
      },
    },
    Annotation: {
      fields: {
        data: {
          merge: false,
        },
      },
    },
    ReporterSettings: {
      keyFields: ['styles', 'mergeFieldsSettings'],
    },
    WorkspacePreset: {
      field: {
        windows: {
          merge: false,
        },
      },
    },
    DicomTag: {
      keyFields: false,
    },

    // Disable Frame caching as it can slow down the app a lot
    Frame: {
      keyFields: false,
    },

    // Structured Report Data are only pulled at the top level
    SRStudy: {
      keyFields: false,
    },
    SRSeries: {
      keyFields: false,
    },
    SRInstance: {
      keyFields: false,
    },
    SRGeneralInfo: {
      keyFields: false,
    },
    SRObservation: {
      keyFields: false,
    },
    SRSOPClass: {
      keyFields: false,
    },
  },
  dataIdFromObject(responseObject: $FlowFixMe) {
    // HangingProtocolLayout has an `id` property that describes the window ID rather than an unique identifier
    // describing the layout configuration, in order to prevent Apollo from wrongly deduplicate these configurations
    // we need to override the ID that Apollo uses to identify the object with one that can be used to identify
    // the layout configuration.
    if (responseObject.__typename === 'HangingProtocolLayout') {
      return `${responseObject.__typename}:${responseObject.id}:${responseObject.layout.join('-')}`;
    }

    // Apollo looks for `id` or `_id` fields to use as unique keys, in our code we usually
    // go with `smid`, so in the code below we tell Apollo to use `smid` as unique key, when available
    if (responseObject.id == null && responseObject._id == null && responseObject.smid != null) {
      return `${responseObject.__typename}:${responseObject.smid}`;
    }

    return defaultDataIdFromObject(responseObject);
  },
});

const cleanTypenameLink = new ApolloLink((operation, forward) => {
  const keysToOmit = ['__typename']; // more keys like timestamps could be included here

  const def = getMainDefinition(operation.query);

  if (def && def.operation === 'mutation') {
    operation.variables = omitDeep(operation.variables, keysToOmit);
  }
  return forward ? forward(operation) : null;
});

const persistedQueryLink = createPersistedQueryLink({
  // eslint-disable-next-line react-hooks/rules-of-hooks
  generateHash: usePregeneratedHashes(persistedQueriesData),
});

const link = ApolloLink.from([
  persistedQueryLink,
  readSessionLink,
  logoutLink,
  cleanTypenameLink,
  precacheLink,
  requiresAuthMiddleware,
  transportLink,
]);
// Configure the ApolloClient with the default cache
const client: ApolloClient<$FlowFixMe> = new ApolloClient({
  name: 'workspace',
  version: APP_VERSION,
  link,
  cache,
  // This speeds up the initial load by 30%
  assumeImmutableResults: true,
});
persistCacheSync({
  client,
  cache,
  storage: new LocalForageWrapper(localforage),
  serialize: false,
});
export { client, cache };
